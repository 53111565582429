import * as Redmine from "@/config/redmine-constants";
import { routes } from "@/router";
import Permission from "./Permission.vue";

export default {
    name: 'Permissions',
    components: {
        Permission,
    },
    data() {
        return {
            users: [
                Redmine.PROFILE_CLIENT,
                Redmine.PROFILE_CONSULTANT,
                Redmine.PROFILE_PROJECT_MANAGER_ASSISTANT,
                Redmine.PROFILE_SUPPORT,
                Redmine.PROFILE_SALES,
                Redmine.PROFILE_EXPERT,
                Redmine.PROFILE_PRESALES,
                Redmine.PROFILE_PROJECT_MANAGER,
                Redmine.PROFILE_PROJECT_DIRECTOR,
            ].map((profile, id) => ({
                id,
                profile,
                admin: false,
            })).concat([
                { id: 42, profile: 'Admin', admin: true },
                { id: 138, profile: 'Account', admin: false },
            ]),
            routes
        };
    }
};
