import { authorizeRule } from "@/app/helpers";

export default {
    name: 'Permission',
    props: ['route', 'users'],
    data() {
        return {
            rules: this.users.map(user => authorizeRule(this.route.meta, user))
        };
    },
};
